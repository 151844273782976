<template>
  <div class="registration-from-qr">
    <template v-if="!isRegistered">
      <div class="registration-from-qr__title mb-9">Create an account</div>
      <v-form ref="form" v-model="valid" lazy-validation id="authForm">
        <v-container class="px-0 py-0">
          <v-row class="mx-0 my-0 px-0 py-0 justify-space-between container-wrapper" style="column-gap: 10px">
            <v-col class="px-0 py-0" style="max-width: 380px">
              <v-row class="mx-0 my-0">
                <v-col class="px-0 py-0">
                  <div class="label mb-1">Name</div>
                  <Input input-class="registration"
                         outlined
                         height="56"
                         radius="16"
                         tabindex="1"
                         background-color="#EDF2F7"
                         placeholder="Your First Name"
                         :rules="notEmtpy"
                         @input="form.firstName=$event"
                         @enter="register"
                  />
                </v-col>
              </v-row>
              <v-row class="mx-0 my-0">
                <v-col class="px-0 py-0" style="position: relative">
                  <div class="label mb-1">Last name</div>
                  <Input input-class="registration"
                         outlined
                         height="56"
                         radius="16"
                         tabindex="2"
                         background-color="#EDF2F7"
                         placeholder="Your Last Name"
                         :rules="notEmtpy"
                         @input="form.lastName=$event"
                         @enter="register"
                  />
                </v-col>
              </v-row>
              <v-row class="mx-0 my-0">
                <v-col class="px-0 py-0">
                  <div class="label mb-1">E-mail</div>
                  <Input input-class="registration"
                         outlined
                         height="56"
                         radius="16"
                         tabindex="3"
                         background-color="#EDF2F7"
                         placeholder="Your Email"
                         :rules="emailRules"
                         @blur="checkEmail"
                         @input="form.email=$event"
                         @enter="register"
                  />
                </v-col>
              </v-row>
              <v-row class="mx-0 my-0">
                <v-col class="px-0 py-0">
                  <div class="label mb-1">Phone</div>
                  <PhoneInput
                    height="56"
                    radius="16"
                    tabindex="4"
                    :value="form.phone"
                    @input="form.phone=$event"
                    :rules="phoneRules"
                  />
                </v-col>
              </v-row>
              <v-row class="mx-0 mt-0 mb-7">
                <v-col class="px-0 py-0">
                  <div class="label mb-1">Gender</div>
                  <div class="gender-switcher d-flex">
                    <Button
                      block
                      dark
                      :class="{ 'active-gender': form.sex === 'Male' }"
                      tabindex="5"
                      height="32"
                      @click.native="updateGender('Male')">
                      Male
                    </Button>
                    <Button
                      block
                      dark
                      :class="{ 'active-gender': form.sex === 'Female' }"
                      tabindex="6"
                      height="32"
                      @click.native="updateGender('Female')">
                      Female
                    </Button>

                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="px-0 py-0 d-flex flex-column" style="max-width: 380px">
              <div class="mb-7">
                <v-row class="mx-0 my-0">
                  <v-col class="px-0 py-0" style="position: relative">
                    <div class="label mb-1">Password</div>
                    <Input input-class="registration"
                           autocomplete="new-password"
                           outlined
                           height="56"
                           radius="16"
                           tabindex="7"
                           background-color="#EDF2F7"
                           placeholder="Type your password"
                           :type="showPassword1 ? 'text' : 'password'"
                           :rules="confirmFormPwdRules"
                           @input="form.password=$event"
                           @focus="showPasswordRequirements = true"
                           @blur="showPasswordRequirements = false"
                    />
                    <div class="icon">
                      <span
                        :class="{
                          'show-pass': showPassword1,
                          'hide-pass': !showPassword1,
                        }"
                        @click="toggleShow(1)"
                      ></span>
                    </div>
                    <div class="pwd-requirements-prompt">
                      <password-requirements
                        v-show="showPasswordRequirements"
                        :verifiablePassword="form.password"
                        :min-pwd-length="6"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mx-0 my-0">
                  <v-col class="px-0 py-0" style="position: relative">
                    <div class="label mb-1">Confirm Password</div>
                    <Input input-class="registration"
                           outlined
                           height="56"
                           radius="16"
                           tabindex="8"
                           background-color="#EDF2F7"
                           placeholder="Type your password"
                           :type="showPassword2 ? 'text' : 'password'"
                           :rules="confirmFormCfmPwdRules"
                           @input="form.confirmPassword=$event"
                    />
                    <div class="icon">
                      <span
                        :class="{
                          'show-pass': showPassword2,
                          'hide-pass': !showPassword2,
                        }"
                        @click="toggleShow(2)"
                      ></span>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mx-0 my-0">
                  <v-col class="px-0 py-0">
                    <div class="label mb-1">Dietary</div>
                    <div class="d-flex registration-from-qr__dietary">
                      <v-checkbox
                        class="pl-0 pr-2 py-3 mt-0"
                        color="#008C89"
                        tabindex="9"
                        v-model="form.vegetarian"
                        label="Vegetarian"/>
                      <v-checkbox
                        color="#008C89"
                        tabindex="10"
                        class="pl-0 pr-2 py-3 mt-0 ml-7"
                        v-model="form.glutenFree"
                        label="Gluten free"/>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="row mx-0 mt-6 mb-0">
                  <v-col class="px-0 py-0">
                    <div class="registration-from-qr__agree d-flex align-start">
                      <checkbox color="#008C89" tabindex="11" :rules="regAgreeRules" @change="form.isAgreedToTerms=$event" />
                      <span class="mr-2">Agree with</span>
                      <a
                        @click="openTermConditions"
                        style="color: #668BFF; font-weight: 600">Terms and Conditions</a>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-row class="mb-7 mx-0">
                <v-col class="px-0 py-0 mt-auto">
                  <div v-if="commonError && commonError.length" style="line-height: 12px;font-size: 12px;color: #ff5252;margin-bottom: 5px;">
                    <div v-for="(err, idx) in commonError" :key="idx">
                      {{err}}
                    </div>
                  </div>
                  <div>
                    <Button btn-bg="dark-green" tabindex="12" @click.native="register" height="56" block dark>Create</Button>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
        <v-progress-circular
          indeterminate
          color="#008C89"
        ></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <div class="registered">
        <img src="../../../assets/icons/icon-done-2.svg" alt="Almost done!">
        <h2 class="registered__title mb-5 mt-6">Nice. Almost done!</h2>
        <div class="registered__info">
          <v-row class="mx-0 mt-0">
            <v-col class="px-0 py-0">
              <p class="registered__info-top-text mb-5">Check your inbox!</p>
              <p class="registered__info-text">
                We've sent a confirmation email to
                <br>
                <span>{{ form.email }}</span>
              </p>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    <terms-conditions v-if="dialogTermsConditions.isOpen"/>
  </div>
</template>

<script>
import Validation from '@/mixins/Validation'
import Input from '@/components/elements/Input'
import PhoneInput from '@/components/elements/phone-input'
import checkbox from '@/components/elements/checkbox'
import Button from '@/components/elements/Button'
import { mapActions, mapMutations, mapState } from 'vuex'
import { MIN_PASS_LENGTH } from '@/constants/const'
import TermsConditions from '@/components/layouts/dialogs/terms-conditions'
import PasswordRequirements from '@/components/elements/PasswordRequirements'

export default {
  name: 'registration-from-qr',
  mixins: [Validation],
  props: {
    responseData: Object
  },
  components: { Input, PhoneInput, checkbox, Button, TermsConditions, PasswordRequirements },
  data: () => ({
    form: {
      URLKey: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      sex: '',
      password: '',
      confirmPassword: '',
      vegetarian: false,
      glutenFree: false,
      isAgreedToTerms: false,
      isCaptchaChecked: true
    },
    showPassword1: false,
    showPassword2: false,
    isRegistered: false,
    showPasswordRequirements: false,
    passwordRequirementsRules: [
      {
        text: `Be at least ${MIN_PASS_LENGTH} characters long`,
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one uppercase',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one lowercase',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one number',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one special characters (!@#)',
        icon: 'mdi-check-circle',
        passedValidation: false
      }
    ],
    commonError: [],
    loading: false
  }),
  mounted () {
    document.title = 'Registration to Maestro Patient Portal'
  },
  methods: {
    ...mapActions(['registrationFromQr', 'vailidateEmail']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    updateGender (lang) {
      this.form.sex = lang
    },
    async checkEmail () {
      const { email } = this.form
      const params = { email, URLKey: this.$route.params.dynamicUrl }
      const res = await this.vailidateEmail(params)
      this.setDynamicValidationRules({
        emailRules: [
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          res.data.validationResults.isValid || res.data.message
        ]
      })
      // this.$set(this.$data, 'isValidEmail', res.data.validationResults.isValid)
    },
    async register () {
      this.loading = true
      this.form.URLKey = this.$route.params.dynamicUrl

      if (!this.validatePassword()) {
        this.loading = false
        return
      }

      if (this.validate()) {
        const result = await this.registrationFromQr(this.form)
        if (result && result.data && result.data.success && result.data.validationResults.isValid) {
          this.isRegistered = true
          this.$emit('showImage')
        }
        /*if (!result.data.validationResults.isValid) {
          if (result.data.validationResults?.results[0].name === 'Email') {
            this.setDynamicValidationRules({
              emailRules: [
                v => !!v || 'Email is required',
                // v => (!v || /.+@.+\..+/.test(v)) || 'E-mail must be valid',
                result.data.validationResults.isValid || result.data.validationResults?.results[0].message
              ]
            })
          }
          if (result.data.validationResults?.results[0].name === 'Common') {
            this.commonError = result.data.validationResults?.results[0].message
          }
        }*/
        if (result.data.errors) {
          this.commonError = Object.keys(result.data.errors)
            .filter(key => key !== "$id")
            .flatMap(key => result.data.errors[key])
        }
      }
      this.loading = false
    },
    openTermConditions () {
      this.TOGGLE_DIALOG({ name: 'termAndConditions', isOpen: true })
    },
    validatePassword () {
      this.setDynamicValidationRules({
        confirmFormPwdRules: [
          v => !!v || 'Password is required.',
          v => !!v || 'This field should not be empty',
          v => (v && v?.length) >= MIN_PASS_LENGTH || `Min ${MIN_PASS_LENGTH} characters`],
        confirmFormCfmPwdRules: [
          v => !!v || 'Confirm password is required.',
          v => (v === this.form.password) || 'This field must match'
        ]
      })

      const isValid = this.$refs.form.validate()
      return isValid
    },
    toggleShow(inputNumber) {
      if (inputNumber === 1) {
        this.showPassword1 = !this.showPassword1
      } else if (inputNumber === 2) {
        this.showPassword2 = !this.showPassword2
      }
    },
  },
  computed: {
    captchaKey () {
      return process.env.VUE_APP_GOOGLE_API_TOKERN
    },
    ...mapState({
      messages: state => state.messages,
      dialogTermsConditions: state => state.dialogs.termAndConditions
    })
  },
  watch: {
    'form.email' (val) {
      if (!val) this.regEmailRules = []
      if (val) {
        this.regEmailRules = [
          v => !!v || 'E-mail is required.',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ]
      }
    },
    'form.password' (val) {
      if (!val) this.confirmFormPwdRules = []
      if (val) {
        this.confirmFormPwdRules = [
          v => !!v || 'Password is required.',
          v => !!v || 'This field should not be empty',
          v => (v && v?.length) >= MIN_PASS_LENGTH || `Min ${MIN_PASS_LENGTH} characters`
        ]
      }
    },
    'form.confirmPassword' (val) {
      if (!val) this.confirmFormCfmPwdRules = []
      if (val) {
        this.confirmFormCfmPwdRules = [
          v => !!v || 'Confirm password is required.',
          v => (v === this.form.password) || 'This field must match'
        ]
      }
    },
    'form.isAgreedToTerms' (val) {
      if (!val) this.regAgreeRules = [v => !!v || 'You must agree to continue!']
      if (val) this.regAgreeRules = []
    },
    messages (val) {
      if (val && val.length > 0) {
        let errMessages = ''
        for (let i = 0; i < val.length; i++) {
          errMessages += `${val[i].msg} `
        }
        this.regEmailRules = [v => errMessages]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  min-height: 100px;
  position: absolute;
  height: 95%;
  width: 110%;
  top: 35px;
  left: -20px;
  background: rgba(213, 213, 213, 0.5);
  border-radius: 16px;
}
.label {
  font-size: 14px;
  font-weight: 500;
  color: #425466;
}
.registration-from-qr {
  width: 100%;
  position: relative;
  &__registration-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #72767F;

    ::v-deep .custom-link {
      a {
        color: #668BFF;
      }
    }
  }
  &__agree {
    font-weight: 400;
    font-size: 16px;
    color: #2D3748;
    position: relative;
    ::v-deep .v-input {
      .v-input__slot {
        margin: 0;

        .v-input--selection-controls__input {
          margin-right: 12px;

          .v-icon {
            color: #C9CED6;
          }
        }
      }
    }
    ::v-deep .v-messages.error--text {
      position: absolute;
      top: 23px;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 28px;
    color: #27272E;
    line-height: 38px;
  }
  &__dietary {
    ::v-deep .v-input {
      .v-input__slot {
        margin: 0;
        .v-input--selection-controls__input {
          margin-right: 12px;
          .v-icon {
            color: #C9CED6;
          }
        }
        .v-label {
            color: #2D3748;
        }
      }
      &__control {
        .v-messages {
          display: none;
        }
      }
    }
  }
  .registered {
    max-width: 380px;
    margin: 0 auto;

    &__title {
      font-weight: 600;
      font-size: 28px;
      line-height: 136.02%;
      color: #27272E;
    }
    &__info-top-text {
      font-weight: 600;
      font-size: 20px;
      color: #2D3748;
    }
    &__info-text {
      font-weight: 400;
      font-size: 16px;
      color: #718096;
      span {
        font-weight: 600;
      }
    }
  }
  .gender-switcher {
    width: 198px;
    background-color: #F7FAFC;
    border-radius: 8px;
    column-gap: 2px;

    .button {
      ::v-deep .v-btn {
        height: 32px !important;
        width: 92px;
        color: #425466;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0;
        background-color: rgba(255, 255, 255, 0.64);
        border-radius: 8px;
        margin: 2px;
        padding: 0;
      }
    }
    .button.active-gender {
      ::v-deep .v-btn {
        background-color: #008C89;
        color: #FFFFFF;
      }
    }
  }
  .pwd-requirements-prompt {
    width: inherit;
    position: absolute;
    z-index: 4;
    right: 2px;
    top: 83px;
  }
  .button {
    ::v-deep .v-btn {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: normal;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;

    span {
      display: inline-block;
      width: 22px;
      height: 22px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-top: 4px;
      cursor: pointer;

      &.show-pass {
        background-image: url('@/assets/icons/show.svg');
      }

      &.hide-pass {
        background-image: url('@/assets/icons/hide.svg');
      }
    }
  }
}

::v-deep .v-text-field__details {
  padding-top: 5px !important;
}
@media screen and (max-width: 768px) {
  .container-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 350px) {
  .registration-from-qr {
    #authForm {
      .registration-from-qr__agree {
        display: unset !important;
        .checkbox {
          display: inline-block !important;
        }
      }
      .registration-from-qr__registration-link {
        display: unset !important;
        .link {
          margin-left: 0!important;
        }
      }
    }
  }
}
</style>
