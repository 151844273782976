<template>
  <div class="login d-flex justify-center">
    <div class="login-area d-flex justify-center" :class="{'width-all': firstTextIsRead && !showBgImage }">
      <div class="login-area__form-wrapper d-flex flex-column">
        <div class="logo flex-grow-1 d-flex align-center" style="column-gap: 10px;">
          <Img :src="responseResult.logoSmall" width="97" />
          <div style="width: 70%">
            <div style="font-weight: 600;font-size: 25px;">{{responseResult.practiceName}}</div>
            <div>{{responseResult.practiceTitle}}</div>
          </div>
        </div>
        <div class="login-area__form flex-grow-1 mt-10">
          <template v-if="firstTextIsRead">
            <registration-from-qr :responseData="responseResult" @showImage="showImage" />
          </template>
          <template v-else>
            <br>
            <h3>Welcome to the Nutrition Assessment!</h3>
            <br>
            <br>
            <p>
              The purpose of the nutrition assessment is to establish a baseline of your current state of health. We evaluate and interpret your responses to make recommendations for nutrition supplementation designed to support the physiological and biochemical processes of your body.
            </p>
            <br>
            <br>
            <Button btn-bg="dark-green" @click.native="firstTextIsRead = true" height="56" block dark>Start my Survey</Button>
            <v-row class="mt-2">
              <v-col>
                <div class="registration-form__registration-link d-flex justify-center" style="color: #72767F;">Already have an account? <Link class="ml-2" to="/sign-in">Sign in</Link></div>
              </v-col>
            </v-row>
          </template>
        </div>
      </div>
    </div>
    <div v-if="!firstTextIsRead" class="slider-area d-none d-lg-block">
      <div class="slider-area__carusel d-flex justify-center align-center">
        <carousel :items="items" />
      </div>
    </div>
    <div v-if="showBgImage" class="slider-area d-none d-lg-block">
      <img class="bg-image" src="@/assets/images/registered-bg.png" alt="image">
    </div>
  </div>
</template>

<script>
import Image from '@/mixins/Image'
import registrationFromQr from '@/components/layouts/forms/registration-from-qr'
import carousel from '@/components/layouts/carusel'
import Img from '@/components/elements/Img'
import Button from '@/components/elements/Button'
import Link from '@/components/elements/Link'
import { mapActions } from 'vuex'

export default {
  name: 'login',
  mixins: [Image],
  components: { registrationFromQr, Link, Img, carousel, Button },
  data: () => ({
    responseResult: {},
    firstTextIsRead: false,
    showBgImage: false
  }),
  mounted () {
    document.title = 'Welcome to Maestro Patient Portal'
  },
  async created () {
    const { dynamicUrl } = this.$route.params
    const result = await this.getInfoByDynamicUrlQr({ dynamicUrl })
    this.responseResult = result.record
  },
  methods: {
    ...mapActions(['getInfoByDynamicUrlQr', 'registrationFromQr']),
    showImage() {
      this.showBgImage = true
    }
  },
  computed: {
    items () {
      return [
        {
          src: this.getImgWithExt(['images', 'slider', 'slide1', 'jpeg']),
          content: 'Feeling Sluggish? Having trouble maintaining a healthy weight?<br> Ask your practitioner if completing a detox or purification program is right for you.'
        },
        {
          src: this.getImgWithExt(['images', 'slider', 'slide2', 'jpeg']),
          content: 'Confused about your protocol? Need to reorder products?<br> You can chat with your practitioner through the Portal and get help or answers to any of your questions.'
        },
        {
          src: this.getImgWithExt(['images', 'slider', 'slide3', 'jpeg']),
          content: 'Our survey is designed to assess your nutritional needs from a<br> whole-body perspective, not just to address the symptoms, but to understand and work to resolve any root causes.'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  .login-area,
  .slider-area {
    width: 50%;
    height: 100vh;
  }
  .login-area {
    background-color: #FFFFFF;
    &.width-all {
      width: 100%;

      .login-area__form-wrapper {
        width: 840px;
      }
    }
    @media (max-width: 960px) {
      width: 100%;
      padding: 0 30px;
    }
    &__form-wrapper {
      width: 554px;
      padding: 15px 0;
    }
    &__form {
      width: 100%;
      h3 {
        font-size: 28px;
        text-align: center;
      }
      p {
        font-size: 18px;
        text-align: center;
        max-width: 505px;
        margin: 0 auto;
      }
      .button {
        max-width: 380px;
        margin: 0 auto;
        ::v-deep .v-btn__content {
          font-weight: 600;
          letter-spacing: normal;
        }
      }
      .link {
        ::v-deep {
          a {
            color: #668BFF;
          }
        }
      }
    }
  }
  .slider-area {
    position: relative;
    background-color: $dark-green;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(151.33deg, #04B8B0 0%, rgba(4, 184, 176, 0) 43.71%);
      z-index: 1;
      pointer-events: none;
    }
    &__carusel {
      position: absolute;
      left: 15%;
      height: 100vh;
    }
  }
  .logo {
    ::v-deep {
      .v-image {
        border-radius: 50% !important;
      }
    }
  }
}
.bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 500px) {
  .login {
    .login-area {
      &__form-wrapper {
        width: 100%;
      }
    }
  }
}
</style>
