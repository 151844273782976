import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '@/views/Index'
import confirmPassword from '@/views/confirm-password'
import confirmLead from '@/views/confirm-lead.vue'

import unauth from '@/views/unauth'
import Visit from '@/views/visit'
import Results from '@/views/results'
import Receipts from '@/views/receipts'
import Schedule from '@/views/schedule'
import Chat from '@/views/chat'
import Profile from '@/views/profile'
import Survey from '@/views/survey'
import History from '@/views/history'
import Evaluations from '@/views/evaluations'
import registrationFromQr from '@/views/registration-from-qr'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Index,
    children: [
      {
        path: '/visit/:visitId',
        name: 'Visit',
        component: Visit,
        props: (route) => {
          return {
            visitId: route.params.visitId
          }
        },
        children: [
          {
            path: '/visit/:visitId/results',
            name: 'Results',
            component: Results,
            props: (route) => {
              return {
                visitId: route.params.visitId
              }
            }
          },
          {
            path: '/visit/:visitId/receipts',
            name: 'Receipts',
            component: Receipts,
            props: (route) => {
              return {
                visitId: route.params.visitId
              }
            }
          },
          {
            path: '/visit/:visitId/schedule',
            name: 'Schedule',
            component: Schedule,
            props: (route) => {
              return {
                visitId: route.params.visitId
              }
            }
          },
          {
            path: '/visit/:visitId/survey',
            name: 'Survey',
            component: Survey,
            props: (route) => {
              return {
                visitId: route.params.visitId
              }
            }
          }
        ]
      },
      // {
      //   path: '/results',
      //   name: 'results',
      //   component: Results
      // },
      {
        path: '/chat',
        name: 'chat',
        component: Chat
      },
      {
        path: '/profile',
        name: 'profile',
        component: Profile
      },
      {
        path: '/survey',
        name: 'Survey',
        component: Survey
      },
      {
        path: '/history',
        name: 'history',
        component: History,
        children: [
          {
            path: 'visit/:visitId',
            name: 'Visit',
            component: Visit,
            props: (route) => {
              return {
                visitId: route.params.visitId
              }
            },
            children: [
              {
                path: '/visit/:visitId/results',
                name: 'Results',
                component: Results,
                props: (route) => {
                  return {
                    visitId: route.params.visitId
                  }
                }
              },
              {
                path: 'receipts',
                name: 'Receipts',
                component: Receipts,
                props: (route) => {
                  return {
                    visitId: route.params.visitId
                  }
                }
              },
              {
                path: 'schedule',
                name: 'Schedule',
                component: Schedule,
                props: (route) => {
                  return {
                    visitId: route.params.visitId
                  }
                }
              },
              {
                path: 'survey',
                name: 'Survey',
                component: Survey,
                props: (route) => {
                  return {
                    visitId: route.params.visitId
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: '/evaluations',
        name: 'Evaluations',
        component: Evaluations,
        children: [
          {
            path: 'visit/:visitId',
            name: 'Visit',
            component: Visit,
            props: (route) => {
              return {
                visitId: route.params.visitId
              }
            },
            children: [
              {
                path: '/visit/:visitId/results',
                name: 'Results',
                component: Results,
                props: (route) => {
                  return {
                    visitId: route.params.visitId
                  }
                }
              },
              {
                path: 'receipts',
                name: 'Receipts',
                component: Receipts,
                props: (route) => {
                  return {
                    visitId: route.params.visitId
                  }
                }
              },
              {
                path: 'schedule',
                name: 'Schedule',
                component: Schedule,
                props: (route) => {
                  return {
                    visitId: route.params.visitId
                  }
                }
              },
              {
                path: 'survey',
                name: 'Survey',
                component: Survey,
                props: (route) => {
                  return {
                    visitId: route.params.visitId
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: unauth
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: unauth
  },
  {
    path: '/confirm-password/:id/:token/:type',
    name: 'ConfirmPassword',
    component: confirmPassword
  },
  {
    path: '/confirm/:id/:token',
    name: 'ConfirmAccount',
    component: unauth
  },
  {
    path: '/s/:dynamicUrl',
    name: 'RegistrationFromQR',
    component: registrationFromQr
  },
  {
    path: '/redirect/:userId/:token',
    name: 'RedirectAutoAuth',
    component: unauth
  },
  {
    path: '/confirmlead/:userId/:token',
    name: 'ConfirmLead',
    component: confirmLead,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state?.isAuth && (to.name !== 'SignIn' && to.name !== 'SignUp' && to.name !== 'ConfirmAccount' && to.name !== 'ConfirmPassword' && to.name !== 'RegistrationFromQR' && to.name !== 'RedirectAutoAuth' && to.name !== 'ConfirmLead')) {
    next({ name: 'SignIn' })
  } else {
    next()
  }
})

export default router
