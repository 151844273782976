<template>
  <div class="confirm-lead d-flex justify-center">
      <div class="confirm-lead__area">
        <div class="confirm-lead__area-wrapper d-flex flex-column">
          <div v-if="responseResult" class="logo flex-grow-1 d-flex align-center" style="column-gap: 10px;">
            <Img :src="responseResult.logoSmall" width="97" />
            <div style="width: 70%">
              <div style="font-weight: 600;font-size: 25px;">{{responseResult.practiceName || '-'}}</div>
              <div>{{responseResult.practiceTitle || '-'}}</div>
            </div>
          </div>
          <div class="confirm-lead__area-content flex-grow-1">
            <template v-if="confirmationSuccess">
              <img class="spinner rotating" src="@/assets/images/spinner.png" alt="Almost done!" />
              <h2 class="registered__title mb-5 mt-6">Nice. Almost done!</h2>
            </template>
            <template v-else>
              <img class="error-icon" src="@/assets/images/attention.png" alt="Attention!" />
              <h2 class="registered__title mb-5 mt-6">Error</h2>
              <p class="mb-0" style="color: #2D3748; font-size: 16px; font-weight: 400">Problem with confirmation reference</p>
              <p class="mb-0" style="color: #2D3748; font-size: 16px; font-weight: 400">{{ errorMessage }}</p>
            </template>
          </div>
        </div>
      </div>
      <div class="bg-area">
        <img v-if="confirmationSuccess" class="bg-image" src="@/assets/images/confirm-bg.png" alt="confirm" />
        <img v-else class="bg-image" src="@/assets/images/error-bg.png" alt="error" />
      </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Image from '@/mixins/Image'
import Img from '@/components/elements/Img'

export default {
  name: 'confirm-lead',
  mixins: [Image],
  components: { Img },

  data: () => ({
    responseResult: {},
    confirmationSuccess: true,
    isLoading: false,
    errorMessage: '',
  }),
  async created () {
    try {
      const { userId, token } = this.$route.params

      const result = await this.getConfirmLeadData({ userId, token })
      this.responseResult = result?.record || null

      if (this.responseResult) {
        const confirmResult = await this.confirmLead({ userId, token })
        this.confirmationSuccess = confirmResult?.status === 200

        if (!this.confirmationSuccess) {
          this.errorMessage = confirmResult.data.message
        } else {
          this.$router.push('/').catch(() => {})
        }
      } else {
        this.confirmationSuccess = false
      }
    }
    catch (error) {
      console.error('Error confirmLead', error)
      this.confirmationSuccess = false
    }
  },
  methods: {
    ...mapActions(['confirmLead', 'getConfirmLeadData'])
  }
}
</script>

<style lang="scss" scoped>
.confirm-lead {
  &__area {
    width: 50%;
    height: 100vh;
    background-color: #FFFFFF;

    @media (max-width: 960px) {
      width: 100%;
      padding: 0 30px;
    }
  }
  &__area-wrapper {
    max-width: 420px;
    padding: 15px 0;
    margin: 0 auto;
  }
  &__area-content {
    margin-top: 150px;
  }
  .bg-area {
    width: 50%;
    height: 100vh;
    position: relative;
    background-color: $dark-green;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(151.33deg, #04B8B0 0%, rgba(4, 184, 176, 0) 43.71%);
      z-index: 1;
      pointer-events: none;
    }

    @media (max-width: 960px) {
     display: none;
    }
  }
  .logo {
    ::v-deep {
      .v-image {
        border-radius: 50% !important;
      }
    }
  }
}

.bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.spinner {
  width: 48px;
  height: 48px;
}

.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

@-webkit-keyframes rotating
  /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(213, 213, 213, 0.5);
}
</style>
