import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import axios from './plugins/axios'
import lodash from './plugins/lodash'
import VueMask from 'v-mask'
import vueMoment from './plugins/moment'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import i18n from './i18n'
import ChatHubPlugin from '@/plugins/chatHubPlugin'

Vue.use(ChatHubPlugin)
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_API_TOKERN })

Vue.use(VueMask)

Vue.config.productionTip = false

new Vue({
  axios,
  lodash,
  vueMoment,
  router,
  store,
  vuetify,
  swiper,
  i18n,
  render: h => h(App)
}).$mount('#app')
